<template>
    <!-- <div> -->
        <v-container>
             <v-form ref="tourform">
                 <div class="date_radios">
                    <span v-for="(r,index) in daysInMonth" :key="index">
                    <input type="radio" name="date" v-model="tour_form.book_date"  :value="r.value" :id="'date'+index" :rules="[rules.required]"  />
                    <label class="radio text-center" :for="'date'+index" style="width: 80px;height: 80px">
                        <span >{{r.text}}</span>
                    </label>
                    </span>
                </div>
                <v-container>
                      <v-menu ref="menu" v-model="time1" :close-on-content-click="false" :nudge-right="40"
                            :return-value.sync="time" transition="scale-transition" offset-y max-width="290px"
                            min-width="200px">
                        <template v-slot:activator="{ on }">
                            <v-text-field v-model="tour_form.book_time" label="Time" clearable outlined  ref="time"
                            :rules="[rules.required]" readonly v-on="on" style="margin-bottom:-20px"></v-text-field>
                        </template>
                        <v-time-picker color="secondary" v-if="time1" v-model="tour_form.book_time" full-width scrollable
                                @click:minute="$refs.menu.save(time)"></v-time-picker>
                    </v-menu>
                </v-container>
                <div class="tour_radios">
                    <span v-for="(r,i) in ['In Person','Virtual']" :key="i">
                    <input type="radio" name="medium" v-model="tour_form.medium"  :value="r" :id="'bed'+ i" :rules="[rules.required]"  />
                    <label class="radio text-center" :for="'bed'+i" style="width: 150px;height: 40px" >
                        <span >{{r}}</span>
                    </label>
                    </span>
                </div>
                <div class="text-center" style="margin-top:20px">
                     <v-btn  :loading="apiloading" :disabled="apiloading" color="secondary" width="90%"
                               @click="scheduleMyTour()" ><span style="color:#fff"><span style="text-transform:capitalize">Schedule Tour</span></span></v-btn>
                </div>
              
        </v-form>
        </v-container>
       
         
    <!-- </div> -->
</template>
<script>
import moment from 'moment'
import { loadingMixin } from '../../mixins/Loading'
import toast from '../../Services/ToastService'
export default {
    inject:["Bus"],
    mixins:[loadingMixin],
    props:["property_id"],
    data() {
        return {
            rules: {
                required: value => !!value || "Required.",
                    phone:value=>{
                const regre = /[0-9]|\./
                    return regre.test(value) || "Invalid number";
                },
            },
            daysInMonth:[],
             time1:false,
            time:null,
            current_time:moment().format('LT'),
            tour_form:{
                 agentid: this.$store.state.user_module.user.id,
                propertyid:this.property_id,
                name:this.$store.state.user_module.user.firstname,
                email:this.$store.state.user_module.user.email,
                phone:this.$store.state.user_module.user.phone,
                book_date:moment().format('YYYY-mm-DD'),
                medium:'In Person',
                book_time:'9:00'
            }
            
        }
    },
    computed: {
    //     computedDateFormattedMomentjs () {
    //     return this.tour_form.date ? moment(this.tour_form.date).format('ddd,Do MMMM,YYYY') : ''
    //   },
    },
    methods: {
        generateDaysInMonths() {
            let startdate = moment().startOf('month');
            let today = moment(new Date());
            let endmonth = moment().endOf('month');
            let diff = endmonth.diff(today,'days')+1;
            // for(let i = 0; i < today.daysInMonth();i++){
            for(let i = 0; i < diff;i++){
                let newDay = today.clone().add(i,'days');
                this.daysInMonth.push({text:newDay.format('dddd DD MMMM YYYY'),value:newDay.format('YYYY-mm-DD')})
            }
        },
      
        scheduleMyTour(){
            if(!this.$refs.tourform.validate()){
                return;
            }
            console.log("tour_form",this.tour_form)
            this.tour_form.propertyid = this.property_id
            this.$store.dispatch("propertyBookTourAction", this.tour_form).then(res=>{
                // this.closeTourModal();
                toast.success("Scheduled sent successfully, The agent will get back to you")
                this.$refs.reset();

            })
        }
    },
    created(){
        this.generateDaysInMonths();

    }
}
</script>
<style scoped>
    .tour_radios{
         margin-top:5px;
        display: flex;
        flex-direction:row;
        width:100%;
        /* flex-wrap: wrap; */
        justify-content: center;
        align-items: center;
        /* border: 1px solid rgba(0, 0, 0, 0.47);
        border-radius: 12px; */
    }
    .tour_radios input[type=radio]{
        display: none;
    }
    .tour_radios label{
        text-align: center;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        /* margin:0 10px; */
        /* padding:5px 0; */
        font-size: 12px;
        /* background-color: #fff; */
         /* background-color: #F8F8F8; */
         border: 1px solid rgba(0, 0, 0, 0.47);
        /* border-radius: 12px; */
     
    }
    .tour_radios label span{
        /* padding:5px; */
          color:rgba(74, 74, 74, 0.69);
    }
    .tour_radios input[type=radio]:checked + .radio{
        /*background-color:  rgba(0, 119, 243, 0.05);*/
        /* background-color: #379392; */
        /*color:#0077F3;*/
        /* color: #fff; */
         /* border-radius: 12px; */
        border: 2px solid #379392;
    }
    .tour_radios input[type=radio]:checked + .radio span{
        /*background-color:  rgba(0, 119, 243, 0.05);*/
        /* background-color: #379392; */
        /*color:#0077F3;*/
        /* color: #fff; */
         /* color:#0077F3; */
    }
    .date_radios{
        margin-top:5px;
        display: flex;
        flex-direction:row;
        min-width:100%;
        justify-content: space-between;
        align-items: center;
        overflow-x:scroll;
        /* padding: 5px 0; */
        overflow-y:hidden;
        min-height: 100px;
        
    }
    .date_radios::-webkit-scrollbar {
    display: none;
    }
    .date_radios input[type=radio]{
        display: none;
    }
    .date_radios label{
       
        /*border-radius: 8px;*/
        text-align: center;
        /*padding:10px;*/
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        padding:5px 10px;
        margin:0 5px;
        font-size: 12px;
        background-color: #fff;
        border: 1px solid rgba(0, 0, 0, 0.47);
        border-radius: 12px;
        height: 100px;
    }
    .date_radios label span{
        padding:5px;
        color:rgba(74, 74, 74, 0.69);
        /*background-color: #379392;*/
    }
    /* .date_radios input[type=radio]:checked + .date_radios label{
         border: 1px solid #379392;
    } */
    .date_radios input[type=radio]:checked + .radio{
        /*background-color:  rgba(0, 119, 243, 0.05);*/
        /* background-color: #379392; */
        /*color:#0077F3;*/
        /* color: #fff; */
         border: 2px solid #379392;
    }
    .date_radios input[type=radio]:checked + .radio span{
        /*background-color:  rgba(0, 119, 243, 0.05);*/
        /* background-color: #379392; */
        /*color:#0077F3;*/
        /* color: #fff; */
    }
</style>>

</style>